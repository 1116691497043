<template>
  <v-container fluid>
    <v-slide-x-transition mode="out-in">
      <div
        v-if="selectedView === '1'"
        key="1"
        class="pa-5"
      >
        <v-flex class="mt-5">
          <h1 class="mb-6">
            Eshop Discount Codes
          </h1>
          <v-spacer />
        </v-flex>
        <v-card elevation="0" rounded="xl">
          <v-card-title>
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="40"
              />
            </template>
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <v-btn
              class="ml-15"
              dark
              color="green"
              @click="selectedView = '2'"
            >
              New Item
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="coupons"
            :search="search"
            :footer-props="footerProps"
            :loading="showProgress"
            loading-text="Loading... Please wait"
          >
            <template v-slot:top>
              <v-dialog
                v-model="dialogDelete"
                max-width="500px"
              >
                <v-card>
                  <v-card-title class="text-h5">
                    Delete this item?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeDelete"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="red darken-1"
                      text
                      @click="deleteItem"
                    >
                      DELETE
                    </v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteDialog(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <div
        v-if="selectedView === '2'"
        key="2"
        class="pl-5 pr-5 bg"
      >
        <v-row class="mt-5">
          <h1 class="mb-6">
            Add Discount Coupon
          </h1>
          <v-spacer />
          <v-btn
            class="ml-15"
            dark
            color="orange"
            @click="selectedView = '1'; clearForm();"
          >
            Go back
          </v-btn>
        </v-row>
        <v-form
          ref="form"
        >
          <div class="mb-4 text-h6">
            Details
          </div>
          <v-row class="mb-1">
            <v-col cols="4">
              <v-text-field
                v-model="cuponId"
                :counter="30"
                label="Coupon Code"
                outlined
                required
              />
            </v-col>
            <!-- <v-col cols="2">
              <v-select
                v-model="couponValidity"
                :items="couponValidityOptions"
                label="Coupon Validity"
                outlined
              />
            </v-col>
            <v-col v-if="couponValidity === 'repeating'" cols="2">
              <v-text-field
                v-model="couponValidityDuration"
                label="Coupon Valid Duration"
                type="number"
                outlined
                required
              />
            </v-col> -->
          </v-row>
          <v-card elevation="3" class="mb-6">
            <v-card-text>
              <v-row>
                <v-col cols="2">
                  <v-checkbox
                    v-model="couponItemSpecific"
                    color="green"
                    label="Item Specific Discount?"
                  />
                </v-col>
                <v-col v-if="couponItemSpecific" cols="10">
                  <v-select
                    v-model="couponValidItems"
                    multiple
                    outlined
                    color="green"
                    :items="shopItems"
                    item-value="product"
                    item-text="name"
                    label="Select Items"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card elevation="3" class="mb-6">
            <v-card-title>
              Discount in SEK or %
            </v-card-title>
            <v-card-text>
              <v-row class="mt-5 mb-1">
                <v-col cols="3">
                  <v-text-field
                    v-model="couponDiscountNumber"
                    label="Discount in SEK (just number)"
                    type="number"
                    :disabled="Number(couponDiscountPercents) ? true : false"
                    outlined
                    required
                  />
                </v-col>
                <div class="mt-6">
                  OR
                </div>
                <v-col cols="3">
                  <v-text-field
                    v-model="couponDiscountPercents"
                    label="Discount in % (just number)"
                    type="number"
                    :disabled="Number(couponDiscountNumber) ? true : false"
                    outlined
                    required
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card elevation="3" class="mb-1">
            <v-card-title>
              Summary
            </v-card-title>
            <v-card-text>
              <v-row class="mb-1">
                <v-col cols="12">
                  <v-divider class="mb-1" />
                  <div v-if="!couponItemSpecific">
                    <div v-for="item in preProcessedEshopItems" :key="item.itemName">
                      <div>
                        {{ item.itemName }} - <span class="blue--text">One Time Purchase</span> : {{ item.itemPrice }} SEK - <span class="green--text font-weight-black">after discount One Time {{ applyDiscount(item.itemPrice) }} SEK</span> <br>
                      </div>
                    </div>
                    <v-divider class="mt-1" />
                  </div>
                  <div v-else>
                    <div v-for="item in couponValidItems" :key="item">
                      <div>
                        {{ lookUpItem(item).itemName }} - One Time: {{ lookUpItem(item).itemPrice }} SEK - <span class="green--text font-weight-black">after discount One Time {{ applyDiscount(lookUpItem(item).itemPrice) }} SEK</span> === -<span v-if="couponDiscountNumber">{{ couponDiscountNumber }}SEK</span> <span v-else-if="couponDiscountPercents">{{ couponDiscountPercents }}%</span> Discount <br>
                      </div>
                    </div>
                    <v-divider class="mt-1" />
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- Butons ------------------- -->
          <v-btn
            v-if="!editing"
            color="success"
            class="mr-4 mt-4"
            :disabled="!cuponId || !couponValidity"
            @click="addCoupon"
          >
            <div v-if="btnLoading">
              <v-progress-circular
                indeterminate
                color="white"
              />
            </div>
            <div v-else>
              Submit
            </div>
          </v-btn>
          <v-btn
            v-else
            color="success"
            class="mr-4 mt-4"
            disabled
            @click="saveEditedCoupon"
          >
            <div v-if="btnLoading">
              <v-progress-circular
                indeterminate
                color="white"
              />
            </div>
            <div v-else>
              Save item
            </div>
          </v-btn>
        </v-form>
      </div>
    </v-slide-x-transition>
  </v-container>
</template>

<script>

export default {
  name: 'Users',
  components: {

  },
  data () {
    return {
      selectedView: '1',
      descriptionSelector: 'itemDescription',
      deleteItemId: 0,
      dialogDelete: false,
      showProgress: true,
      btnLoading: false,
      pullingData: false,
      editing: false,
      cuponId: '',
      couponValidity: 'once',
      couponValidityDuration: null,
      couponItemSpecific: false,
      couponValidItems: [],
      couponDiscountNumber: null,
      couponDiscountPercents: null,
      pagination: {},
      search: '',
      coupons: [],
      shopItems: [],
      preProcessedEshopItems: [],
      processedShopItems: [],
      headers: [
        { text: 'Coupon Code', value: 'couponId' },
        { text: 'Stripe Coupon Id', value: 'stripeCouponId' },
        { text: 'Discount SEK', value: 'couponDiscountNumber' },
        { text: 'Discount %', value: 'couponDiscountPercents' },
        { text: 'Discounted Items', value: 'couponValidItems' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      footerProps: {
        'items-per-page-options': [10, 30, 50, 100]
      },
      isMounted: false
    }
  },
  computed: {
  },
  watch: {
    // selectedView: function (val) {
    //   if (val === '2') {
    //     this.couponItemSpecific = true
    //     if (!this.couponValidItems.length) {
    //       this.shopItems.forEach(i => {
    //         if (i.type === 'One Time Price') {
    //           this.couponValidItems.push(i.productId)
    //         }
    //       })
    //     }
    //   }
    // }
  },
  mounted () {
    this.pullCouponsData()
    this.isMounted = true
  },
  methods: {
    pullCouponsData () {
      this.showProgress = true
      this.$store.dispatch('pullEshopDiscountCoupons').then(resp => {
        if (resp) {
          this.coupons = resp.data.coupons
          const eshopItems = resp.data.items
          this.preProcessedEshopItems = resp.data.items
          const resultEshopItems = []
          eshopItems.forEach(i => {
            resultEshopItems.push({
              name: `${i.itemName}`,
              product: i.productId
            })
            // if (i.productPriceId) {
            //   resultEshopItems.push({
            //     name: `${i.itemName}`,
            //     type: 'One Time Price',
            //     price: i.productPriceId
            //   })
            // }
            // if (i.productPriceSubId) {
            //   resultEshopItems.push({
            //     name: `${i.itemName}`,
            //     type: 'Subscription Price',
            //     price: i.productPriceSubId
            //   })
            // }
            // if (i.productSubUpfrontPriceId) {
            //   resultEshopItems.push({
            //     name: `${i.itemName}`,
            //     type: 'Subscription Upfront Price',
            //     price: i.productSubUpfrontPriceId
            //   })
            // }
            // if (i.homevisionPlusPriceId) {
            //   resultEshopItems.push({
            //     name: `${i.itemName}`,
            //     type: 'Homevision Plus Price',
            //     price: i.homevisionPlusPriceId
            //   })
            // }
          })
          this.shopItems = resultEshopItems
          this.showProgress = false
        }
        this.showProgress = false
      })
    },
    addCoupon () {
      this.btnLoading = true
      this.$store.dispatch('addDiscountCoupon', {
        couponId: this.cuponId,
        couponValidity: this.couponValidity,
        couponValidityDuration: this.couponValidityDuration,
        couponItemSpecific: this.couponItemSpecific,
        couponValidItems: this.couponValidItems,
        couponDiscountNumber: this.couponDiscountNumber,
        couponDiscountPercents: this.couponDiscountPercents
      }).then(resp => {
        this.btnLoading = false
        this.pullCouponsData()
        this.selectedView = '1'
        this.clearForm()
      })
    },
    editItem (item) {
      this.editing = true
      this.cuponId = item.couponId
      this.couponValidity = item.couponValidity
      this.couponValidityDuration = item.couponValidityDuration
      this.couponItemSpecific = item.couponItemSpecific
      this.couponValidItems = item.couponValidItems
      this.couponDiscountNumber = item.couponDiscountNumber
      this.couponDiscountPercents = item.couponDiscountPercents
      this.selectedView = '2'
    },
    saveEditedCoupon () {
      this.btnLoading = true
      this.$store.dispatch('editEshopItem', {
        couponId: this.cuponId,
        couponValidity: this.couponValidity,
        couponValidityDuration: this.couponValidityDuration,
        couponItemSpecific: this.couponItemSpecific,
        couponValidItems: this.couponValidItems,
        couponDiscountNumber: Number(this.couponDiscountNumber),
        couponDiscountPercents: Number(this.couponDiscountPercents)
      }).then(resp => {
        this.btnLoading = false
        this.pullCouponsData()
        this.selectedView = '1'
        this.clearForm()
      })
    },
    closeDelete () {
      this.dialogDelete = false
    },
    deleteDialog (item) {
      this.dialogDelete = true
      this.deleteItemId = item.id
    },
    deleteItem () {
      this.$store.dispatch('deleteEshopItem', { itemId: this.deleteItemId }).then(() => {
        this.selectedView = '1'
        this.clearForm()
        this.pullEshopData()
        this.closeDelete()
      })
    },
    clearForm () {
      this.editing = false
      this.cuponId = ''
      this.couponValidity = ''
      this.couponValidityDuration = null
      this.couponItemSpecific = false
      this.couponValidItems = []
      this.couponDiscountNumber = null
      this.couponDiscountPercents = null
    },
    applyDiscount (itemPrice) {
      let result
      if (this.couponDiscountNumber) {
        result = itemPrice - this.couponDiscountNumber
      } else {
        result = itemPrice - (itemPrice * (this.couponDiscountPercents / 100))
      }
      return result
    },
    lookUpItem (item) {
      const eshopItems = this.preProcessedEshopItems
      return eshopItems.find(i => i.productId === item)
    },
    mapItemTypeColor (item) {
      let result = ''
      if (item === 'One Time Price') {
        result = 'blue--text'
      } else if (item === 'Subscription Price') {
        result = 'orange--text'
      } else if (item === 'Subscription Upfront Price') {
        result = 'green--text'
      } else if (item === 'Homevision Plus Price') {
        result = 'red--text'
      }
      return result
    }
  }
}
</script>

<style scoped>
</style>
